import { useState, Ref, forwardRef, InputHTMLAttributes, ChangeEvent, FocusEvent } from 'react';
import ExclamationSquareIcon from '@stageplus/icons/react/exclamation-square';
import clsx from 'clsx';

type ComponentInputTextProps = {
  label: string;
  helperText?: string;
  error?: boolean;
  warning?: boolean;
  /**
   * A component to be rendered at the end of the input. Useful for adding icons.
   *
   * @example
   * ```tsx
   * <InputText label="Email" name="email" defaultValue="" endAdornment={<EmailIcon />} />
   * ```
   */
  endAdornment?: React.ReactNode;
  /**
   * Render textarea for multiline input
   */
  multiline?: boolean;
} & InputHTMLAttributes<TextInputElement>;

type TextInputElement = HTMLInputElement | HTMLTextAreaElement;

type TextInputElementProps = InputHTMLAttributes<TextInputElement>;

/**
 * A text input field with label and helper text.
 *
 * @example
 * <InputText label="Email" helperText="Please enter your email address" name="email" defaultValue="" error />
 */
export default forwardRef<TextInputElement, ComponentInputTextProps>(function InputText(
  componentProps: ComponentInputTextProps,
  forwardedRef: Ref<TextInputElement>,
) {
  const {
    id,
    name,
    value,
    label,
    error = false,
    warning = false,
    multiline = false,
    helperText,
    disabled,
    readOnly,
    endAdornment,
    ...rest
  } = componentProps;
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const isActive = (typeof value === 'string' && value.length > 0) || isFocused;

  function handleFocus(event: FocusEvent<TextInputElement>) {
    setIsFocused(true);
    componentProps.onFocus?.(event);
  }

  function handleBlur(event: FocusEvent<TextInputElement>) {
    if (!event.target.value) setIsFocused(false);
    componentProps.onBlur?.(event);
  }

  function handleChange(event: ChangeEvent<TextInputElement>) {
    componentProps.onChange?.(event);
  }

  // ID of an input element, use name as a fallback if an ID wasn't provided
  const fieldId = id ?? name;
  // ID of a helper text container to show information and errors
  const helperTextId = helperText ? `${fieldId}-helper` : undefined;

  return (
    <div data-test={`input-text:${name}`}>
      <div className="relative overflow-hidden">
        <Input
          multiline={multiline}
          className={clsx(
            'dg-text-regular-6 w-full transform-gpu rounded-[3px] border-b-2 bg-white/5 pb-1 pl-3 pr-4 pt-4 text-white transition-colors duration-300 focus:border-white/100 focus:outline-none md:rounded-[6px] md:pb-2 md:pt-[1.125rem]',
            error ? 'border-errorOrangeC6' : warning ? 'border-info' : 'border-white/30',
            (readOnly || disabled) && 'text-white/70',
          )}
          autoComplete="off"
          {...rest}
          disabled={disabled}
          readOnly={readOnly}
          value={value || ''}
          id={fieldId}
          name={name}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          aria-invalid={error}
          aria-describedby={helperTextId}
          forwardedRef={forwardedRef}
        />
        <label
          className={clsx(
            'dg-text-regular-6 pointer-events-none absolute left-3 top-2.5 origin-top-left whitespace-nowrap transition-all duration-200 md:top-3.5',
            isActive ? '-translate-y-2 scale-75 opacity-50' : 'translate-y-0 scale-100 opacity-75',
          )}
          htmlFor={fieldId}
        >
          {label}
        </label>
        {endAdornment && <div className="absolute inset-y-0 right-0 flex items-center">{endAdornment}</div>}
      </div>
      {helperText && (
        <div
          id={helperTextId}
          className={clsx(
            'dg-text-regular-8 mt-2 flex flex-row items-center gap-1',
            error || warning ? 'text-errorOrangeC6' : 'text-white text-opacity-70',
          )}
          data-test={error ? 'input-text-error' : 'input-text-helper'}
          aria-live="assertive"
        >
          {(error || warning) && <ExclamationSquareIcon className="shrink-0" />}
          {helperText}
        </div>
      )}
    </div>
  );
});

type InputElementProps = TextInputElementProps &
  Pick<ComponentInputTextProps, 'multiline'> & { forwardedRef: Ref<TextInputElement> };

function Input({ multiline, forwardedRef, ...props }: InputElementProps) {
  if (multiline)
    return <textarea {...(props as TextInputElementProps)} ref={forwardedRef as Ref<HTMLTextAreaElement>} />;
  return <input {...(props as TextInputElementProps)} ref={forwardedRef as Ref<HTMLInputElement>} />;
}
