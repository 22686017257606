import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const useEmailFromQuery = () => {
  const { isReady, query } = useRouter();
  const [email, setEmail] = useState<null | string>(null);

  useEffect(() => {
    if (isReady && query.email) {
      setEmail(Array.isArray(query.email) ? query.email[0] : query.email);
    }
  }, [isReady, query]);

  return email;
};
