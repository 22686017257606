import type { SVGProps } from 'react';
const SvgShow = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12 17.848c5.202 0 8.77-4.204 8.77-5.51 0-1.313-3.575-5.51-8.77-5.51-5.127 0-8.777 4.197-8.777 5.51 0 1.306 3.643 5.51 8.777 5.51m0-1.928a3.6 3.6 0 0 1-3.596-3.582C8.397 10.335 9.997 8.756 12 8.756a3.57 3.57 0 0 1 3.596 3.582c0 1.948-1.607 3.582-3.596 3.582m0-2.276c.725 0 1.32-.595 1.32-1.306 0-.718-.595-1.313-1.32-1.313s-1.32.595-1.32 1.313c0 .71.595 1.306 1.32 1.306"
    />
  </svg>
);
export default SvgShow;
