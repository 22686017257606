import { ComponentProps } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import InputText from 'src/components/common/input-text';

export type ControlledInputTextProps<T extends FieldValues> = ComponentProps<typeof InputText> & UseControllerProps<T>;

/**
 * Extends the `InputText` component to add the ability to control the value of the input through the `useController` hook.
 * @see https://react-hook-form.com/api/usecontroller/
 *
 * @example
 * ```tsx
 * const { control } = useForm<FormValues>();
 * <ControlledInputText label="Name" name="name" defaultValue="" control={control} rules={{ required: "Name is required" }} />
 * ```
 */
export default function ControlledInputText<T extends FieldValues>({
  label,
  control,
  name,
  defaultValue,
  rules,
  helperText,
  warning,
  ...inputProps
}: ControlledInputTextProps<T>) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error },
  } = useController({
    control,
    name,
    defaultValue,
    rules,
  });

  return (
    <InputText
      label={label}
      error={invalid}
      warning={error?.type === 'warning' || warning}
      helperText={error?.message || helperText}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      aria-required={Boolean(rules?.required)}
      ref={ref}
      {...inputProps}
    />
  );
}
