import type { SVGProps } from 'react';
const SvgHide = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12 15.688a3.895 3.895 0 0 1-3.91-3.637L5.219 9.836c-.383.492-.739.984-1.012 1.531a.9.9 0 0 0-.082.383q0 .245.082.41C5.684 15.06 8.637 17 12 17c.71 0 1.422-.11 2.106-.273l-1.395-1.094c-.246.055-.492.055-.711.055m8.559 1.613-3.008-2.352a9.2 9.2 0 0 0 2.215-2.789.9.9 0 0 0 .082-.383q0-.245-.082-.41C18.289 8.47 15.336 6.5 12 6.5c-1.477.027-2.844.383-4.047 1.04L4.48 4.86a.46.46 0 0 0-.273-.083c-.137 0-.273.055-.328.164l-.547.684a.46.46 0 0 0-.082.273.39.39 0 0 0 .164.329l16.078 12.441a.46.46 0 0 0 .274.082.44.44 0 0 0 .355-.164l.52-.684a.46.46 0 0 0 .082-.273.39.39 0 0 0-.164-.328m-5.004-3.883-1.094-.848c.11-.246.137-.52.164-.82v-.027a2.614 2.614 0 0 0-2.598-2.598c-.246 0-.492.055-.738.11.164.218.246.492.273.765-.027.11-.027.191-.054.273l-2.024-1.53c.684-.575 1.559-.903 2.516-.93a3.956 3.956 0 0 1 3.937 3.937c0 .602-.164 1.148-.382 1.668"
    />
  </svg>
);
export default SvgHide;
