import type { SVGProps } from 'react';
const SvgExclamationSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fill="currentColor"
      d="M17 5H7c-1.125 0-2 .906-2 2v10c0 1.125.875 2 2 2h10c1.094 0 2-.875 2-2V7c0-1.094-.906-2-2-2m-5.75 3.75A.74.74 0 0 1 12 8a.76.76 0 0 1 .75.75v4a.74.74 0 0 1-.75.75.72.72 0 0 1-.75-.75zM12 16.5a.98.98 0 0 1-1-.969.98.98 0 0 1 1-.969c.531 0 .969.438.969.97A.974.974 0 0 1 12 16.5"
    />
  </svg>
);
export default SvgExclamationSquare;
